<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      Data Tugas Akhir
      <router-link slot="right" to="/thesis/create" class="button is-primary">
        Tambah
      </router-link>
      <a :href="csv" download="data.csv" target="_blank" slot="right" class="button is-info ml-2">Unduh</a>
    </hero-bar>
    <section class="section is-main-section">

      <div class="card has-table has-mobile-sort-spaced">
        <header class="card-header">
          <p class="card-header-title">
            <b-icon icon="account-multiple" custom-size="default" />
            Tugas Akhir
          </p>
          <b-field class="card-header-icon">
              <b-input placeholder="Search..."
                  type="search"
                  size="is-small"
                  icon="magnify"
                  v-model="searchWord"
                  @input="search(searchWord)">
              </b-input>
          </b-field>
        </header>
        <div class="card-content">
          <modal-box
            :is-active="isModalActive"
            :trash-object-name="trashObjectName"
            @confirm="trashConfirm"
            @cancel="trashCancel"
          />
          <b-table
            :checked-rows.sync="checkedRows"
            :loading="isLoading"
            :paginated="paginated"
            :per-page="perPage"
            :striped="true"
            :hoverable="true"
            :data="data"
          >
            <b-table-column label="Mahasiswa" field="email" sortable v-slot="props">
              {{ props.row.mahasiswa }}
            </b-table-column>
            <b-table-column label="Judul" field="name" sortable v-slot="props">
              {{ props.row.title }}
            </b-table-column>
            <b-table-column label="Pembimbing" field="email" sortable v-slot="props">
              {{ props.row.pembimbing1 }} {{ props.row.pembimbing2  === null ? '' : `- ${props.row.pembimbing2}` }}
            </b-table-column>
            <b-table-column label="Sidang" v-slot="props">
              <small class="has-text-grey is-abbr-like" :title="props.row.tanggal_sidang">{{ props.row.tanggal_sidang }} {{ props.row.sesi === null || props.row.tanggal_sidang === null ? '' : `- Sesi ${props.row.sesi}` }}</small>
            </b-table-column>
            <b-table-column label="Status" v-slot="props">
              <span v-html="getStatus(props.row)"> </span>
            </b-table-column>
            <b-table-column custom-key="actions" cell-class="is-actions-cell" v-slot="props">
              <div class="buttons is-right">
                <router-link :to="{name:'thesis.show', params: {id: props.row.id}}" >
                  <b-button
                      type="is-success"
                      size="is-small"
                      icon-left="magnify" />
                </router-link>
                <router-link :to="{name:'thesis.edit', params: {id: props.row.id}}" class="button is-small is-primary">
                  <b-icon icon="account-edit" size="is-small"/>
                </router-link>
                <button class="button is-small is-danger" type="button" @click.prevent="trashModal(props.row)">
                  <b-icon icon="trash-can" size="is-small"/>
                </button>
              </div>
            </b-table-column>

            <section slot="empty" class="section">
              <div class="content has-text-grey has-text-centered">
                <template v-if="isLoading">
                  <p>
                    <b-icon icon="dots-horizontal" size="is-large" />
                  </p>
                  <p>Fetching data...</p>
                </template>
                <template v-else>
                  <p>
                    <b-icon icon="emoticon-sad" size="is-large" />
                  </p>
                  <p>Nothing's here&hellip;</p>
                </template>
              </div>
            </section>
          </b-table>
        </div>
      </div>

    </section>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import axios from 'axios'
import ModalBox from '@/components/ModalBox'

export default {
  name: 'Tables',
  data () {
    return {
      isModalActive: false,
      trashObject: null,
      oriData: [],
      data: [],
      searchWord: null,
      isLoading: false,
      paginated: false,
      perPage: 10,
      checkedRows: []
    }
  },
  components: {
    HeroBar,
    TitleBar,
    ModalBox
  },
  computed: {
    csv () {
      let data = ''

      if (this.oriData.length > 0) {
        data = 'data:text/csv;charset=utf-8,'
        data += '"ba","judul","mahasiswa","kbk","pembimbing1","pembimbing2","ketua_sidang","penguji1","penguji2","nilai_pembimbing","nilai_ketua_sidang","nilai_penguji1","nilai_penguji2","nilai_akhir","nilai_mutu","tanggal_sidang","sesi"'
        data += '\n'

        this.oriData.forEach(e => {
          const tmp = [
            `"${e.noid}"`,
            `"${e.title}"`,
            `"${e.mahasiswa}"`,
            `"${e.kbk}"`,
            `"${e.pembimbing1}"`,
            `"${e.pembimbing2}"`,
            `"${e.ketua_sidang}"`,
            `"${e.penguji1}"`,
            `"${e.penguji2}"`,
            `"${e.nilai_pembimbing}"`,
            `"${e.nilai_ketua_sidang ? e.nilai_ketua_sidang.average.toFixed(1) : ''}"`,
            `"${e.nilai_penguji1 ? e.nilai_penguji1.average.toFixed(1) : ''}"`,
            `"${e.nilai_penguji2 ? e.nilai_penguji2.average.toFixed(1) : ''}"`,
            `"${e.nilai_akhir}"`,
            `"${e.nilai_mutu}"`,
            `"${e.tanggal_sidang}"`,
            `"${e.sesi}"`
          ]

          data += tmp.join(',')
          data += '\n'
        })
      }
      return encodeURI(data)
    },
    titleStack () {
      return ['Home', 'Data Tugas Akhir']
    },
    trashObjectName () {
      if (this.trashObject) {
        return this.trashObject.name
      }

      return null
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    getStatus (row) {
      const status = [
        '<span class="tag is-info">Selesai</span>',
        '<span class="tag is-warning">Menunggu Jadwal Sidang</span>',
        '<span class="tag is-warning">Menunggu Penilaian</span>'
      ]

      if (!row.tanggal_sidang || !row.ketua_sidang_id || !row.penguji1_id || !row.penguji2_id) {
        return status[1]
      }

      if (!row.nilai_pembimbing || !row.nilai_penguji1 || !row.nilai_penguji2 || !row.nilai_ketua_sidang) {
        return status[2]
      }

      return status[0]
    },
    getData () {
      this.isLoading = true
      axios
        .get('/thesis')
        .then((r) => {
          this.isLoading = false
          if (r.data && r.data.data) {
            if (r.data.data.length > this.perPage) {
              this.paginated = true
            }
            this.data = r.data.data
            this.oriData = r.data.data
          }
        })
        .catch((e) => {
          this.isLoading = false
          this.$buefy.toast.open({
            message: `Error: ${e.message}`,
            type: 'is-danger'
          })
        })
    },
    search (word) {
      if (word === null || word === '') {
        this.getData()
        return false
      }

      console.log(`search for ${word}`)
      const result = this.oriData.filter(obj => {
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(obj)) {
          // console.log(`${word} in ${value} ${String(value).toLowerCase().indexOf(word)}`)
          if (String(value).toLowerCase().indexOf(word.toLowerCase()) >= 0) {
            return true
          }
        }
      })

      this.data = result
    },
    trashModal (trashObject) {
      this.trashObject = trashObject
      this.isModalActive = true
    },
    trashConfirm () {
      this.isModalActive = false

      axios
        .delete(`/thesis/${this.trashObject.id}`)
        .then((r) => {
          console.log(r.data)
          if (r.data.status === 'ok') {
            this.$buefy.toast.open({
              message: r.data.message,
              queue: false
            })
            this.$router.go()
          } else {
            this.$buefy.toast.open({
              message: r.data.message,
              type: 'is-danger',
              queue: false
            })
          }
        })
        .catch((e) => {
          this.$buefy.toast.open({
            message: `Error: ${e.message}`,
            type: 'is-danger',
            queue: false
          })
        })
    },
    trashCancel () {
      this.isModalActive = false
    }
  }
}
</script>
